var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isFromDocument ? _vm.data.properties.filed_content != 'Hide' : true),expression:"!isFromDocument ? data.properties.filed_content != 'Hide' : true"}],style:(_vm.getElementStyle)},[_c('div',{style:(_vm.isFromDocument ? _vm.getStyleInDocument : '')},[_c('div',{class:_vm.isFromDocument ? '' : '',style:(_vm.isFromDocument ? _vm.getStyleInDocument : '')},[_c('el-row',{attrs:{"align":"middle"}},[(
              _vm.data &&
              _vm.data.properties &&
              !_vm.data.properties.hideLabel &&
              _vm.hasLabel
            )?_c('el-col',{style:(_vm.computedStyles),attrs:{"span":_vm.showLabel && !_vm.isDefalutPosq ? 4 : _vm.data.description ? 4 : 24}},[(!_vm.fromRepeatable && !_vm.isFromDocument && !_vm.profilePage)?_c('label',{staticStyle:{"white-space":"nowrap"},style:(_vm.getLabelStyles),attrs:{"for":"text"}},[_vm._v(_vm._s(_vm.label||_vm.data.label))]):_vm._e(),(_vm.data.validations.required)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]):_vm._e(),(_vm.data.description && !_vm.isFromDocument)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.data.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1):(!_vm.isFromDocument)?_c('el-col',{ref:"colRef",style:(_vm.computedStyles),attrs:{"span":_vm.showLabel ? 4 : _vm.data.description ? 20 : 24}},[(_vm.hasLabel)?_c('span',{staticStyle:{"color":"white","display":"inline-block","height":"20px","opacity":"0"}}):_vm._e()]):_vm._e(),_c('el-col',{attrs:{"span":_vm.showLabel && !_vm.isDefalutPosq ? 12 : 24}},[(!_vm.isView)?_c('div',{style:(_vm.isFromDocument ? _vm.getStyleInDocument : '')},[(_vm.checkIsCurrency || _vm.data.validations.currency)?_c('div',{style:(_vm.isFromDocument ? _vm.getStyleInDocument : '')},[(_vm.data.validations.currency)?_c('currency-input',{staticClass:"el-input__inner",class:{
                    'disabled-field': _vm.checkIsDisabled,
                    'left-aligned-input': _vm.textAlignment === 'left',
                    'right-aligned-input': _vm.textAlignment === 'right',
                    'center-aligned-input': _vm.textAlignment === 'center',
                  },style:(_vm.isFromDocument ? _vm.getStyleInDocument : ''),attrs:{"locale":_vm.data.validations.locale,"placeholder":_vm.data.key,"currency":_vm.data.validations.currency,"readonly":true,"id":_vm.data.key,"suffix-icon":_vm.data.properties && _vm.data.properties.el_icon
                      ? _vm.data.properties.el_icon
                      : ''},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_c('currency-input',{class:_vm.isFromDocument ? 'custom-input' : 'el-input__inner',style:(_vm.isFromDocument ? _vm.getStyleInDocument : ''),attrs:{"placeholder":_vm.data.key,"currency":_vm.checkIsCurrency.currency_type,"readonly":true,"id":_vm.data.key,"suffix-icon":_vm.data.properties && _vm.data.properties.el_icon
                      ? _vm.data.properties.el_icon
                      : ''},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}})],1):_c('div',{style:(_vm.isFromDocument ? _vm.getStyleInDocument : '')},[(_vm.data.currency)?_c('span',[_c('currency-input',{staticClass:"el-input__inner",class:{
                      'disabled-field': _vm.checkIsDisabled,
                      'left-aligned-input': _vm.textAlignment === 'left',
                      'right-aligned-input': _vm.textAlignment === 'right',
                      'center-aligned-input': _vm.textAlignment === 'center',
                    },style:(_vm.isFromDocument ? _vm.getStyleInDocument : ''),attrs:{"placeholder":_vm.data.key,"currency":_vm.data.currency,"readonly":true,"id":_vm.data.key,"suffix-icon":_vm.data.properties && _vm.data.properties.el_icon
                        ? _vm.data.properties.el_icon
                        : ''},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}})],1):_vm._e(),(_vm.checkIsTime && !_vm.isDate(_vm.form[_vm.data.key]))?_c('input',{staticClass:"nfe",style:(Object.assign({}, {'text-align': _vm.inputTextAlignment},
                    (_vm.isFromDocument ? _vm.getStyleInDocument : _vm.getStyle))),attrs:{"placeholder":_vm.data.key,"readonly":true,"suffix-icon":_vm.data.properties && _vm.data.properties.el_icon
                      ? _vm.data.properties.el_icon
                      : '',"id":_vm.data.key},domProps:{"value":_vm.getTimeFormatedValue(_vm.form[_vm.data.key])}}):_vm._e(),(
                    !_vm.data.currency &&
                    !_vm.isFromDocument &&
                    !_vm.checkIsTime &&
                    !_vm.isDate(_vm.form[_vm.data.key])
                  )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form[_vm.data.key]),expression:"form[data.key]"}],staticClass:"nfe",class:{
                    'disabled-field': _vm.checkIsDisabled,
                    'left-aligned-input': _vm.textAlignment === 'left',
                    'right-aligned-input': _vm.textAlignment === 'right',
                    'center-aligned-input': _vm.textAlignment === 'center',
                  },style:(Object.assign({}, {'text-align': _vm.inputTextAlignment},
                    (_vm.isFromDocument ? _vm.getStyleInDocument : _vm.getStyle))),attrs:{"placeholder":_vm.data.key,"readonly":true,"id":_vm.data.key,"suffix-icon":_vm.data.properties && _vm.data.properties.el_icon
                      ? _vm.data.properties.el_icon
                      : ''},domProps:{"value":(_vm.form[_vm.data.key])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, _vm.data.key, $event.target.value)}}}):_vm._e(),(
                    !_vm.data.currency &&
                    _vm.isFromDocument &&
                    !_vm.checkIsTime &&
                    !_vm.isDate(_vm.form[_vm.data.key])
                  )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form[_vm.data.key]),expression:"form[data.key]"}],staticClass:"el-input",style:(Object.assign({}, {'text-align': _vm.inputTextAlignment},
                    _vm.getStyleInDocument)),attrs:{"placeholder":_vm.data.key,"readonly":true,"suffix-icon":_vm.data.properties && _vm.data.properties.el_icon
                      ? _vm.data.properties.el_icon
                      : '',"id":_vm.data.key},domProps:{"value":(_vm.form[_vm.data.key])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, _vm.data.key, $event.target.value)}}}):_vm._e(),(_vm.isTime(_vm.form[_vm.data.key]))?_c('el-input',{attrs:{"placeholder":_vm.data.placeholder || _vm.data.label,"prefix-icon":"el-icon-date","readonly":true},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_vm._e(),(_vm.isDate(_vm.form[_vm.data.key]) && !_vm.isTime(_vm.form[_vm.data.key]))?_c('el-date-picker',{attrs:{"prefix-icon":"el-icon-date","readonly":true,"type":"date","placeholder":_vm.data.placeholder || _vm.data.label,"format":_vm.getDefaultDateFormat &&
                    _vm.getDefaultDateFormat.includes('Do') == false
                      ? _vm.getDefaultDateFormat
                          .replace('YYYY', 'yyyy')
                          .replace('DD', 'dd')
                      : 'MM-dd-yyyy'},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_vm._e()],1)]):_vm._e()]),(_vm.isView)?_c('div',{style:({ textAlign: _vm.inputTextAlignment() })},[(_vm.form[_vm.data.key] !== undefined && _vm.form[_vm.data.key] !== null)?[(_vm.data.validations.currency)?_c('span',[_vm._v(" "+_vm._s(_vm._f("toCurrency")(Number(_vm.form[_vm.data.key]),_vm.data.validations.currency))+" ")]):(_vm.checkIsTime)?_c('span',[_vm._v(" "+_vm._s(_vm._f("toTimeFormat")(_vm.form[_vm.data.key],_vm.data.time_result_type))+" ")]):(_vm.isDate(_vm.form[_vm.data.key]))?_c('div',[_vm._v(" "+_vm._s(_vm._f("globalDateFormat")(_vm.form[_vm.data.key]))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.form[_vm.data.key])+" ")])]:[_vm._v("-")]],2):_vm._e()],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }